<template>
  <div class="">
    <div class="flex justify-between items-center mb-6">
      <div>
        <h1 class="text-4xl">Generate Worksheet</h1>
        <!-- <p class="text-sm text-gray-400">Welcome to your dashboard</p> -->
      </div>
    </div>

    <div class="bg-white w-full p-6 rounded-lg shadow">
      <form class="w-full" @submit.prevent="showConfirmModal">
        <div class="flex flex-wrap -mx-3 mb-6 border-b pb-3 border-gray-200">
          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
              Select Date
            </label>
            <input @change="loadData()"
              class="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-first-name" type="date" v-model="form.date" />
            <!-- <p class="text-red-500 text-xs italic">
              Please fill out this field.
            </p> -->
          </div>
          <div class="w-full md:w-1/2 px-3"></div>
        </div>

        <div v-if="form.date">
          <template v-if="(myworksheet && myworksheet) || myworksheet.totalSales > 0
            ">
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-zip">
                Total Deposits
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                @copy.prevent id="grid-zip" type="text" readonly v-model="form.totalDeposits" />
            </div>

            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0 mt-6">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-zip">
                Total Cashpower Sales
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                @copy.prevent id="grid-zip" type="text" readonly v-model="form.totalCashpowerSales" />
            </div>

            <div class="w-full md:w-1/2 px-3 mb-6 mt-4 md:mb-0">
              <h1 class="text2xl font-extrabold">
                TOTAL: <small>GMD</small>
                {{ formatPrice(form.totalDeposits + form.totalCashpowerSales) }}
              </h1>
            </div>

            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0 mt-6">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-zip">
                Cash in hand
              </label>
              <input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="cashInHand" type="number" @paste.prevent @keypress="isNumber($event)" min="100" required
                v-model="form.cashInHand" />
            </div>

            <div class="flex">
              <div class="w-50 md:w-1/4 px-3 mt-6">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-zip">
                  Shortage
                </label>
                <input
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-zip" type="number" readonly :value="getShortage()" />
              </div>

              <div class="w-50 md:w-1/4 px-3 mt-6">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-zip">
                  Surplus
                </label>
                <input
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-zip" type="number" readonly :value="getSurplus()" />
              </div>
            </div>

            <div class="flex flex-wrap -mx-3 px-3 mt-8">
              <button @click="showConfirmModal()" class="bg-green-400 hover:bg-green-500 px-11 py-3 rounded-full">
                Generate
              </button>
            </div>
          </template>
        </div>

        <div class="text-center" v-else>
          <p>Select a date to generate worksheet</p>
        </div>

        <confimation v-show="isConfirmModalVisible" modalHeadline="Please confirm?" :isLoading="isLoading"
          :confirmMessage="`Are you sure, Please confirm your cash in hand is GMD${form.cashInHand}`"
          @confirmEvent="confirmRequest" @close="closeConfirmModal" />
      </form>
    </div>
  </div>
</template>

<script>
import Form from "vform";
import axios from "axios";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

import Confimation from "../../components/Confimation.vue";

export default {
  layout: "default",
  middleware: "auth",
  components: { Confimation },

  data: () => ({
    myworksheet: null,
    isLoading: false,
    errorMsg: "",
    isConfirmModalVisible: false,
    userHasConfirmed: false,
    form: new Form({
      date: null,
      totalDeposits: 0,
      totalCashpowerSales: "",
      cashInHand: 0,
      shortage: 0,
      surplus: 0,
    }),
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },

  methods: {
    getShortage() {
      if (!this.myworksheet) {
        return false;
      }

      let total =
        Number(this.myworksheet.totalDeposits) + Number(this.myworksheet.totalSales);

      let sum = total - Number(this.form.cashInHand);

      if (sum < 0) {
        return 0;
      }
      return total - Number(this.form.cashInHand);
    },

    getSurplus() {
      if (!this.myworksheet) {
        return false;
      }

      let total =
        Number(this.myworksheet.totalDeposits) + Number(this.myworksheet.totalSales);

      let sum = total;

      if (this.form.cashInHand > sum) {
        return Math.abs(sum - this.form.cashInHand);
      }

      return 0;
    },

    showConfirmModal() {
      this.isConfirmModalVisible = true;
    },
    closeConfirmModal() {
      this.isConfirmModalVisible = false;
    },

    confirmRequest(req) {
      console.log("User has confirmed", req);
      this.userHasConfirmed = req;

      if (this.userHasConfirmed == "yes") {
        this.onSubmit();
      }
    },

    async onSubmit() {
      // Submit the form
      this.isLoading = true;

      try {
        await this.form.post("/generateWorksheet");

        this.isLoading = false;
        this.$router.go(-1);

        await this.$store.dispatch("auth/fetchUser");

        // this.$router.push({ path: "/branches" });

        return true;
      } catch (e) {
        this.isLoading = false;

        console.log("Error generating worksheet", e.response.data);
        Swal.fire({
          icon: "error",
          title: "Error generating worksheet",
          text: e.response.data.error || "Sorry, something went wrong"
        });
        return false;
      }
    },

    async loadData() {
      try {
        const { data } = await axios.get(`/myworksheet?date=${this.form.date}`);

        console.log(data);

        this.myworksheet = data;

        this.form.totalDeposits = this.formatPrice(this.myworksheet.totalDeposits);
        this.form.totalCashpowerSales = this.formatPrice(this.myworksheet.totalSales);

        // console.log("The current page is: ", this.agents.meta.current_page);

        return true;
      } catch (e) {
        console.log("Error", e.response.status);
        if (e.response.status == 409) {
          Swal.fire({
            icon: "warning",
            title: "Worksheet already generated!",
            text: `Sorry a worksheet for ${this.user.data.name} on ${this.form.date} already exists`,
          });
        }

        return false;
      }
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>
